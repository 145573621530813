import type { FC } from "preact/compat";
import { isRingPackSelected, isSoldOut } from "../models/products.ts";

type Props = {
  taxesText: string;
  feeText: string;
};

export const DeliveryCost: FC<Props> = ({ taxesText, feeText }) => {
  if (isSoldOut.value) {
    return null;
  }

  return (
    <div className="checkout__total-notes" id="taxes-and-shipping-text">
      <p>{taxesText}</p>
      {!isRingPackSelected.value && <p>{feeText}</p>}
    </div>
  );
};
